<template>
  <div>
    <el-dialog :close-on-click-modal="false" v-model="dialogTableVisible" title="一键生成凭证">
      <div class="title">
        <i
          class="iconfont icon-gantanhao1"
          style="color:red;margin-right: 5px;"
        ></i
        >请注意，计提税金取数不重置手动修改的报表
      </div>
      <el-table :data="gridData" border ref="mainTable" @selection-change="handleSelectionChange" >
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column property="name" label="名称" width="200" />
        <el-table-column property="status" label="状态" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.status == '未开始'">
              <i class="iconfont icon-gantanhao"></i>
              <span>{{scope.row.status}}</span>
            </div>
            <div class="item_icon" v-else-if="scope.row.status == '进行中'">
              <i class="iconfont icon-info"></i>
              <span>{{scope.row.status}}</span>
            </div>
            <div class="item_icon" v-else-if="scope.row.status == '完成'">
              <i class="iconfont icon-duihao"></i>
              <span>{{scope.row.status}}</span>
            </div>
            <div class="item_icon" v-else>
              <i class="iconfont icon-cuowu"></i>
              <span>{{scope.row.status}}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" @click="startTask" v-loading="isLoading" :disabled="isLoading">
            一键生成凭证
          </el-button>
          <el-button type="primary" size="small" @click="dialogTableVisible = false" v-if="showSureBtn">
            确 定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>

<settingDialog ref="settingDialog"></settingDialog>

</template>

<script>
import {  oneKeyVoucher, voucherSort } from "@/api/voucher.js";
import { salaryCarryOver,carryOverAsset } from '@/api/carryover.js'
import {  taxCreate } from "@/api/taxCalculation"
import { profitAndLoss } from '@/api/carryover.js'



export default {
  name: 'oneKeyVoucher',
  props:{
    overVoucher:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    overVoucher(){
      this.overVoucher1 = this.overVoucher
    },
    deep:true,
    immediate:true

  },
  data() {
    return {
      gridData:[
        {
          name: "票据凭证",
          status: "未开始"
        },
        {
          name: "计提/发放工资",
          status: "未开始"
        },
        {
          name: "资产折旧",
          status: "未开始"
        },
        {
          name: "计提税金(税务报表取数)",
          status: "未开始"
        },
        {
          name: "结转损益(财务报表取数)",
          status: "未开始"
        },
        
      ],
      dialogTableVisible:false,
      multipleSelection: [],
      isLoading:false,
      overVoucher1:false,
      settingMsg:'',
      showSureBtn:false
    };
  },
  created(){
  },
  mounted() {
    
  },

  methods: {
    async startTask(){
      if(this.multipleSelection.length == 0){
        this.$qzfMessage('请至少选择一项',1)
        return
      }
      if(this.overVoucher1){
        this.$qzfMessage('请勿连点,30秒后重试',1)
        return
      }
      this.isLoading = true
      if(this.getIs("票据凭证")){
        this.gridData[0].status = '进行中'
        const sortType = localStorage.getItem('bankSortType') * 1
        const sortField = localStorage.getItem('bankSortField')
        await oneKeyVoucher({type:'qingdan',sortType:sortType,sortField:sortField}).then(res => {
          if(res.data.msg == 'success'){
            this.gridData[0].status = '完成'
            this.$bus.emit('invioceInUpdate')
            this.$bus.emit('invioceOutUpdate')
            this.$bus.emit('invioceFeeUpdate')
          }else{
            this.gridData[0].status = res.data.msg
            if(res.data.msg == '未设置进项税额科目' || res.data.msg == '未设置未认证进项税额科目' || res.data.msg == '未设置销项税额科目' || res.data.msg == '未设置费用现金结算科目' || res.data.msg == '未设置现金结算科目'){
              this.settingMsg = res.data.msg
            }
          }
        })
      }
      if(this.getIs("计提/发放工资")){
        this.gridData[1].status = '进行中'

        await salaryCarryOver({}).then(res => {
          if(res.data.msg == 'success'){
            this.gridData[1].status = '完成'
            this.$bus.emit('salarySheetUpdate')
          }else{
            this.gridData[1].status = res.data.msg
            if(res.data.msg == '未设置生产成本科目' || res.data.msg == '未设置工程施工科目' || res.data.msg == '未设置制造费用科目' || res.data.msg == '未设置劳务成本科目' || res.data.msg == '未设置销售费用科目' || res.data.msg == '未设置薪酬类其他科目'|| res.data.msg == '未设置管理费用科目'|| res.data.msg == '未设置代扣社保科目'|| res.data.msg == '未设置代扣公积金科目'|| res.data.msg == '未设置代扣个人所得税科目'|| res.data.msg == '未设置应付职工薪酬科目'|| res.data.msg == '未设置代扣其他科目'|| res.data.msg == '未设置劳务报酬贷科目'|| res.data.msg == '未设置劳务报酬借科目'|| res.data.msg == '未设置全年一次性奖金贷科目'|| res.data.msg == '未设置全年一次性奖金借科目' || res.data.msg == '未设置经营所得贷科目' || res.data.msg =='未设置经营所得借科目'){
              this.settingMsg = res.data.msg
            }
            if(res.data.msg == '请先前往税务报表页面设置所得税计提科目'){
              this.settingMsg = "请先完成计提科目配置"
            }
          }
        })
      }
      if(this.getIs("资产折旧")){
        this.gridData[2].status = '进行中'

        await carryOverAsset({}).then(res => {
          if(res.data.msg == 'success'){
            this.gridData[2].status = '完成'

            this.$bus.emit('assetsUpdate')
            this.$bus.emit('bankUpdate')
            this.$bus.emit('cashUpdate')
            this.$bus.emit('billUpdate')
            this.$bus.emit('inventoryManageUpdate')
            this.$bus.emit('inventoryPeriodUpdate')

          }else{
            this.gridData[2].status = res.data.msg
          }
        })
      }
      if(this.getIs("计提税金(税务报表取数)")){
        this.gridData[3].status = '进行中'

        await taxCreate({}).then(res => {
          if(res.data.msg == 'success'){
            this.gridData[3].status = '完成';
            this.$bus.emit("financeUpdate")
          }else{
            this.gridData[3].status = res.data.msg
            if(res.data.msg == '请先完成计提科目配置'){
              this.settingMsg = res.data.msg
            }
          }
        })
      }
      if(this.getIs("结转损益(财务报表取数)")){
        this.gridData[4].status = '进行中'
        //断号重排一下
        await voucherSort({})

        await profitAndLoss({}).then(res => {
          if(res.data.msg == 'success' && res.data.data.status){
            this.gridData[4].status = '完成'
          }else{
            if(res.data.msg != 'success'){
              this.gridData[4].status = res.data.msg
              if(res.data.msg == '未设置本年利润科目' || res.data.msg == '未设置未分配利润科目'){
                this.settingMsg = res.data.msg
              }
            } else {
              let msg = ""
              res.data.data.list.map(v=>{
                v.errs.map(item=>{
                  if(item.match('错误')){
                  msg += item
                 }
                })
              })
              this.gridData[4].status = msg
            }
          }
        })
      }
      // 获取未设置科目的
      if(this.settingMsg){
        let route = '/setting/habbitSettingBook'
        this.$refs.settingDialog.getInit(this.settingMsg,route)
      }
      this.$bus.emit('voucherUpdate')
      this.isLoading = false
      this.$emit('success',this.overVoucher1)
      this.showSureBtn = true
    },
    getIs(type){
      let staus = false
      this.multipleSelection.map(v=>{
        if(type == v.name){
          staus = true
          return
        }
      })
      return staus
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    openDialog(){
      this.dialogTableVisible = true 
      this.showSureBtn = false
      this.gridData = [
        {
          name: "票据凭证",
          status: "未开始"
        },
        {
          name: "计提/发放工资",
          status: "未开始"
        },
        {
          name: "资产折旧",
          status: "未开始"
        },
        {
          name: "计提税金(税务报表取数)",
          status: "未开始"
        },
        {
          name: "结转损益(财务报表取数)",
          status: "未开始"
        },
      ]
      this.$nextTick(()=>{
        if(this.multipleSelection.length  == 0){
          this.$refs.mainTable.toggleAllSelection()
        }
      })

    }
  },
};
</script>

<style lang="scss" scoped>
.item_icon{
  display: inline-block;
  width: 58%;
  padding: 1px 3px;
  padding-right: 10px;
  
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  span{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
    margin-left: 4px;
  }
}
.title {
  background-color: #fef1ed;
  font-size: 13px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: red;
}
</style>
<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close :title="dialogTitle" v-model="dialogFormVisible" width="500px">
    <el-form>
      <el-form-item label="类型" v-if="type">
        <el-radio v-model="copyVoucherParam.type" :label="1">复制</el-radio>
        <el-radio v-model="copyVoucherParam.type" :label="2">冲红</el-radio>
      </el-form-item>
      <el-form-item label="账期">
        <el-select size="small" v-model="copyVoucherParam.period" placeholder="请选择账期"  v-if="!voucherType">
            <el-option
            v-for="(item,index) in periodList" :key="index" :label="item.period" :value="item.period"
            >
            <span style="float: left">{{ item.name }}</span>
            <span v-if="item.settleStatus == '4'" style="float: right;color: green;font-size: 14px;">已结账</span>
            <span v-else-if="item.settleStatus" style="float: right;font-size: 14px;">未结账</span>
            </el-option>
       </el-select>
        <el-select v-model="copyVoucherParam.period" placeholder="请选择账期" v-if="voucherType" size="small">
          <el-option v-for="(item,index) in periodList" :key="index" :label="item.period" :value="item.period"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveVoucherCopy" size="small" :loading="loading">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { saveEaVoucherCopy } from "@/api/voucher.js";
import { copyHisVoucher }  from "@/api/old.js"
export default {
  name: 'duplicate',
  props:{
    voucherType:{
      default:'',
      type:String
    },
    type:{
      default:true,
      type:Boolean
    },
    dialogTitle:{
      default:'复制或冲红凭证',
      type:String
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      copyVoucherParam:{
        // id: 0,
        type: 1,
        period: this.$store.getters['user/comInfo'].period
      },
      periodList:[],
      loading:false
    }
  },
  methods: {
    init(row,val) {
      if(this.voucherType){
        this.periodList = this.$store.getters['commons/periods'][1].options
      }else{
        this.periodList = this.$store.getters['commons/periods'][0].options
      }
      this.copyVoucherParam.ids = row
      this.dialogFormVisible = true
      if(!this.type){
        this.copyVoucherParam.type = val*1
      }
    },
    saveVoucherCopy(){
      this.loading = true
      if(this.voucherType == "oldVoucher"){
        let param = {
          id:this.copyVoucherParam.ids,
          period:this.copyVoucherParam.period,
          type:this.copyVoucherParam.type
        }
        copyHisVoucher(param).then(res => {
          this.loading = false
          if(res.data.msg == "success"){
            let str  =  this.copyVoucherParam.type == 1 ? '复制成功' : '已冲红'
            this.$qzfMessage(str)
            this.dialogFormVisible = false
            this.$emit('success')
          }
        })
      }else{
        saveEaVoucherCopy(this.copyVoucherParam).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
          let str  =  this.copyVoucherParam.type == 1 ? '复制成功' : '已冲红'
          this.$qzfMessage(str)
          this.dialogFormVisible = false
          this.$emit('success')
          }
        })
      }
      
    },
  }
}
</script>

<style>

</style>
<template>
  <div class="app-container none-border" v-loading="loading2">
    <div class="top_btns">
      <div class="left_btns">
        <el-input size="small" class="width120" v-model="listQuery.voucherNo" placeholder="请输入凭证号" @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary"   @click="getList" class="mr-10">
          <el-icon><Search /></el-icon> <span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form class="styleForm">
            <el-form-item label="审核状态 ：" :label-width="formLabelWidth" prop="taxId">
              <el-radio-group v-model="listQuery.checkStatus" class="ml-4">
                <el-radio :label="0" size="small">全部发票</el-radio>
                <el-radio :label="2" size="small">未审核</el-radio>
                <el-radio :label="1" size="small">已审核</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="账期 ：" :label-width="formLabelWidth" prop="taxId">
              <el-date-picker size="small" v-model="listQuery.period" type="month" placeholder="请选择账期" value-format="YYYYMM" format="YYYY-MM" style="width:234px"></el-date-picker>
            </el-form-item> -->
            <el-form-item label="会计期间 ：" :label-width="formLabelWidth">   
              <qzf-period v-model:period="listQuery.beginTime" class="w-110" :hidden="true"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime" class="w-110 mr-4" :hidden="true"></qzf-period>
            </el-form-item>
            <el-form-item label="凭证来源 ：" :label-width="formLabelWidth">
              <el-select v-model="listQuery.fromType" placeholder="请选择来源" size="small" class="w-234" filterable>
                <el-option v-for="item in fromOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="科目名称 ：" :label-width="formLabelWidth">
              <el-input size="small" class="w-234" v-model="listQuery.itemName" placeholder="请输入科目名称"></el-input>
            </el-form-item>
            <el-form-item label="金额区间 ：" :label-width="formLabelWidth">
              <el-input size="small" class="w-110" v-model="listQuery.startMoney" placeholder="金额起"></el-input>
              <span style="margin:0 4px">-</span>
              <el-input size="small" class="w-110" v-model="listQuery.endMoney" placeholder="金额止"></el-input>
            </el-form-item>
            <el-form-item label="凭证日期 ：" :label-width="formLabelWidth">
              <el-date-picker
                style="width: 110px;"
                size="small"
                value-format="YYYY-MM-DD"
                v-model="listQuery.startDate"
                type="date"
                placeholder="凭证日期起"
              >
              </el-date-picker>
              <span style="margin:0 4px">-</span>
              <el-date-picker
                style="width: 110px;"
                size="small"
                value-format="YYYY-MM-DD"
                v-model="listQuery.endDate"
                type="date"
                placeholder="凭证日期止"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="凭证号区间 ：" :label-width="formLabelWidth">
              <el-input-number size="small" class="w-110" controls-position="right" :min="1" v-model.number="listQuery.startVoucherNo" placeholder="凭证号起"></el-input-number>
              <span style="margin:0 4px">-</span>
              <el-input-number size="small" class="w-110" controls-position="right" :min="1" v-model.number="listQuery.endVoucherNo" placeholder="凭证号止"></el-input-number>
            </el-form-item>
            <el-form-item label="摘要 ：" :label-width="formLabelWidth">
              <el-input size="small" class="w-234" v-model="listQuery.summary" placeholder="请输入摘要"></el-input>
            </el-form-item>
          </el-form>
        </search>
        <el-switch
          v-model="showStatus"
          active-text="账期选择"
          inactive-text=""
          size="small"
          style="margin-left:10px"
          @change="changeShowStatus"
        ></el-switch>
        <span class="change-style" @click="changeMode">
          切换样式：
          <i class="icon iconfont icon-caidan" v-if="modeType"></i>
          <i class="icon iconfont icon-liebiaoxingshi"  v-else></i> 
        </span>
      </div>
      <div class="right_btn">
        <!-- <qzf-video vid="4467be5374cd64dcec5917c6bb165b4a_4"></qzf-video> -->

        <span style="margin-right:10px;font-size:13px">共{{total}}条凭证</span>
        <qzf-button button_code="pz_tj" :loading="loading" size="small" type="primary" @success="addVoucherDemo" v-if="[3576, 2014].includes(orgId)">
          <el-icon><Menu /></el-icon><span  > 汇总凭证</span>
        </qzf-button>
        <el-dropdown split-button type="success" @click="addVoucher" size="small" style="margin:0 10px" :hide-on-click="false" v-if="$buttonStatus('pz_tj')">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span size="small" @click="openCom">导入</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button button_code="pz_yjscpz" :loading="loading" size="small" type="primary" @success="becomeVoucher" >
          <el-icon><Ticket /></el-icon><span  > 一键生成凭证</span>
        </qzf-button>
        <el-button :disabled="!$buttonStatus('pz_fz')"  size="small" type="primary" plain @click="batchCopy('fz')" >
          <el-icon><DocumentCopy /></el-icon> <span  >复制</span>
        </el-button>
        <el-button :disabled="!$buttonStatus('pz_fz')"  size="small" type="primary" plain @click="batchCopy('hc')" >
          <el-icon><ScaleToOriginal /></el-icon> <span  >红冲</span>
        </el-button>
        <qzf-button button_code="pz_plsc" size="small" type="danger" @success="delAll()"   >
          <el-icon><Delete /></el-icon> <span  >删除</span>
        </qzf-button>
        <!-- <el-dropdown type="success" size="small" @command="handleCommand1" style="margin-left:6px;margin-right:6px" split-button v-if="$buttonStatus('pz_yjjz')">
          <span @click="carryForward"><icon class="el-icon-thumb"></icon> 一键结转</span>
          <template #dropdown>
            <el-dropdown-menu>
            <el-dropdown-item command="b">工资结转成费用</el-dropdown-item>
            <el-dropdown-item command="d">固定资产累计折旧</el-dropdown-item>
          </el-dropdown-menu>
          </template>
        </el-dropdown>
        <settings  :type="['code_onkeyvoucher_salary', 'code_onkeyvoucher_asset']" v-if="$buttonStatus('pz_sz')"></settings> -->
        <el-dropdown @command="handleCommand" size="small" style="margin-left:10px">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <!-- <el-dropdown-item command="a" v-if="$buttonStatus('pz_xgsz')">
                <span>习惯设置</span>
              </el-dropdown-item> -->
              <el-dropdown-item command="b" v-if="$buttonStatus('pz_yjpx')">
                <span>一键排序</span>
              </el-dropdown-item>
              <el-dropdown-item command="c" v-if="$buttonStatus('pz_rqpx')">
                <span>日期排序</span>
              </el-dropdown-item>
              <el-dropdown-item command="d" v-if="$buttonStatus('pz_sdhb')">
                <span>手动合并</span>
              </el-dropdown-item>
              <el-dropdown-item @click="dayin()" v-if="$buttonStatus('pz_dy')">
                <span >打印</span>
              </el-dropdown-item>
              <el-dropdown-item @click="daochuVoucher()" v-if="$buttonStatus('pz_dc')">
                <span >导出</span>
              </el-dropdown-item>
              <el-dropdown-item @click="drag()" v-if="$buttonStatus('pz_tdpx')">
                <span >拖动排序</span>
              </el-dropdown-item>
              <el-dropdown-item @click="huidui()" v-if="$buttonStatus('pz_jzhdsy')">
                <span >结转汇兑损益</span>
              </el-dropdown-item>
              <!-- <el-dropdown-item @click="batchCopy()" v-if="$buttonStatus('pz_plfz')">
                <span >批量复制</span>
              </el-dropdown-item> -->
              <el-dropdown-item @click="rate()" v-if="$buttonStatus('pz_hltz')">
                <span >汇率调整</span>
              </el-dropdown-item>
              <!-- <el-dropdown-item @click="recycle()">
                <span >回收站</span>
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <slidePeriod
      @success="getPeriod"
      ref="slidePeriod"
      style="position: absolute; z-index: 999"
      :contentStyleObj2=220
      v-if="showStatus"
    ></slidePeriod>
    <div v-if="modeType">
      <el-table stripe :data="list" @sort-change="changeSort" v-loading="loading1" style="float: right;" :style="{width:showStatus?'calc(100% - 114px)' :'100%'}" border :height="contentStyleObj" @select="handleSelectionChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="selection" width="55" />
        <el-table-column align="center" prop="voucherNo" label="凭证字号" width="70">
          <template #default="scope">
            <span>
              <el-tooltip content="点击查看凭证" placement="top"  effect="dark">
                <i style="cursor:pointer;color:#39b0d2" class="iconfont icon-pingzheng"  @click="handleUpdate(scope.row)"></i>
              </el-tooltip>
              <el-tooltip content="点击修改凭证号" placement="top"  effect="dark">
                <qzf-button :disabled="!$buttonStatus('pz_xgpzh')" @success="paixu(scope.row)" style="cursor:pointer;color:#39b0d2;position:relative;top:-3px;font-size:13px;margin-left:5px;padding: 0;" type="text"> {{ scope.row.voucherNo }}</qzf-button>
              </el-tooltip>
            </span>
            <!-- <div v-if="scope.row.voucherId" style="font-size:11px;color:red;font-weight:500">已冲红</div> -->
          </template>
        </el-table-column>
        <el-table-column align="center" sortable prop="voucherDate" class-name="col-voucher-date" label="日期" width="100">
          <template #default="scope">
            <div class="voucher-date">
              <span>{{ $parseTime(scope.row.voucherDate, "{y}-{m}-{d}")}}</span>
              <el-button class="fold_icon" v-if="scope.row.voucherItem?.length > voucherItemsLimit" @click="scope.row.showAll = !scope.row.showAll" size="small">
                {{ !scope.row.showAll ? '展开全部' : '收起全部' }}
                <el-icon v-if="!scope.row.showAll">
                  <ArrowDown />
                </el-icon>
                <el-icon v-else>
                  <ArrowUp />
                </el-icon>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="摘要" width="250">
          <template #default="scope">
            <template v-for="(item, i) in scope.row.voucherItem">
              <p v-if="i < voucherItemsLimit || scope.row.showAll" :key="item.id">
                <el-tooltip
                  effect="dark"
                  :disabled="isShowTooltip"
                  placement="top-start"
                  >
                  <template #content>
                    <div style="max-width: 300px;">
                      {{ item.summary }}
                    </div> 
                  </template>
                  <span :ref="item.summary" @mouseover="onMouseOver(item.summary)">{{item.summary}}</span> 
                </el-tooltip>
              </p>
            </template>
          </template>
        </el-table-column>

        <el-table-column align="center" label="科目" width="250">
          <template #default="scope">
            <div class="subject-box">
              <template v-for="(item, i) in scope.row.voucherItem">
                <p class="detail-p" v-if="i < voucherItemsLimit || scope.row.showAll" :key="item.id">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top-start"
                    :disabled="isShowTooltip" 
                    raw-content
                    >
                    <template #content>
                      <div style="max-width: 500px;">
                        {{item.fzhsName?item.subjectName+'--辅助核算：'+item.fzhsName:item.subjectName}}
                      </div> 
                    </template>
                    <span :ref="item.subjectName + item.fzhsName" @mouseover="onMouseOver(item.subjectName + item.fzhsName)">{{item.fzhsName?item.subjectName+'--辅助核算：'+item.fzhsName:item.subjectName}}</span> 
                  </el-tooltip>
                </p>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="借方">
          <template #default="scope">
            <template v-for="(item, i) in scope.row.voucherItem">
              <p v-if="i < voucherItemsLimit || scope.row.showAll" :key="item.id">
                {{item.inAmount? this.$comdify(item.inAmount):""}}
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="贷方">
          <template #default="scope">
            <template v-for="(item, i) in scope.row.voucherItem">
              <p v-if="i < voucherItemsLimit || scope.row.showAll" :key="item.id">
                {{item.outAmount?this.$comdify(item.outAmount):""}}
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="billCount" label="附件张数" width="60"></el-table-column>
        <el-table-column align="center" prop="fromType" label="凭证来源"  width="100">
          <template #default="scope">
            <span>{{$VoucherSourceFilter(scope.row.fromType)}}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="createName" label="制单人" width="80">
          <template #default="scope">
            <span>{{scope.row.createdBy == 1 ? '系统自动' : scope.row.createName}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="checkName" label="审核人" width="80"></el-table-column>
        <!-- <el-table-column align="center" prop="checkStatus" label="审核状态"></el-table-column> -->
        <el-table-column label="审核状态" align="center" width="80"> 
          <template #default="scope">
            <div v-if="scope.row.checkStatus == 0">
              <i class="iconfont icon-gantanhao" style="margin-right: 4px;font-size: 13px;"></i>未审核
            </div>
            <div v-else>
              <i class="iconfont icon-duihao" style="margin-right: 4px;font-size: 13px;"></i>已审核
            </div>
          </template>
        </el-table-column>
        
        <el-table-column align="center" prop="address" label="操作" width="220">
          <template #default="scope">
            <qzf-button button_code="pz_bj" @success="handleUpdate(scope.row)" size="small" type="text" icon="Edit" :disabled="scope.row.period != accountBookPeriod">编辑</qzf-button>
            <!-- <el-button  :disabled="!$buttonStatus('pz_fz')" @click="handleDup(scope.row)" size="small" type="text" style="margin:0">复制</el-button> -->
            <el-button  @click="insertVoucher(scope.row)" size="small" type="text" style="margin:0" :disabled="!$buttonStatus('pz_cr') || scope.row.period != accountBookPeriod" icon="Connection">插入</el-button>
            <el-button  @click="checkPdf(scope.row)" size="small" type="text" style="margin:0" :disabled="scope.row.fromType != '1' && scope.row.fromType != '2' && scope.row.fromType != '6'" icon="Printer">影像</el-button>
            <!-- <qzf-button button_code="pz_plsc" size="small" @success="delVou(scope.row)" type="text" style="margin:0">删除</qzf-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="main_list" v-else :style="{width:showStatus?'calc(100% - 114px)' :'100%'}" style="float: right">
      <div class="main_left"></div>
      <div class="main_right">
        <div class="top_title">
          <!-- <div class="left_box" @click="allSelect">
            <input type="checkbox" :checked="isAllSelected" :style="{marginLeft:0,marginTop:'10px'}"/>
          </div> -->
          <div class="top-nav right_box">
            <el-row>
            <el-col :span="9">
              <div style="display: flex;align-items: center;">
                <input type="checkbox" :checked="isAllSelected" :style="{marginLeft:0}" @click="allSelect"/>
                <div class="box-content" style="border-left:none;width: 90%;">摘要</div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="box-content">科目</div>
            </el-col>
            <el-col :span="3">
              <div class="box-content">借方金额</div>
            </el-col>
            <el-col :span="3">
              <div class="box-content">贷方金额</div>
            </el-col>
            </el-row>
          </div>
       </div>
       <div v-loading="loading1">
       <el-scrollbar :style="{height:contentStyleObj1}" @scroll="scrollEvent" id="boxScrollbar" ref="scrollbar">
          <el-empty :image-size="150" v-if="this.list.length == 0"></el-empty>
          <div class="content_center"  v-for="(item,index) in list" :key="index">
            <!-- <div class="left_box fl" @click="singleSelect(item,index)">
              <input type="checkbox" :checked="item.checked" :style="{marginLeft:0,marginTop:'10px'}"/>
            </div> -->
            <div class="pic">
              <img src="../../assets/wsh.png" alt="" style="width:85px" v-if="item.checkStatus == 0">
              <img src="../../assets/shtg.png" alt="" style="width:85px" v-else>
              </div>
            <div class="content-nav right_box fl">
             
              <el-row class="content-top">
                <el-col :span="24" class="box-content clearfix">
                  <ul class="top_font clearfix" style="width:70%">
                    <li style="padding-left: 10px;" @click="singleSelect(item,index)">
                      <input type="checkbox" :checked="item.checked" :style="{marginLeft:0,marginTop:'10px'}"/>
                    </li>
                    <li style="width:80px">
                      <span style="color:#39b0d2">{{ $parseTime(item.voucherDate, "{y}-{m}-{d}")}}</span>
                    </li>
                    <li style="width:80px">
                      <el-tooltip content="点击查看凭证" placement="top"  effect="dark">
                        <i style="cursor:pointer;color:#39b0d2" class="iconfont icon-pingzheng"  @click="handleUpdate(item)"></i>
                      </el-tooltip>
                      <el-tooltip content="点击修改凭证号" placement="top"  effect="dark">
                        <qzf-button :disabled="!$buttonStatus('pz_xgpzh')" @success="paixu(item)" style="cursor:pointer;color:#39b0d2;position:relative;top:-3px;font-size:13px;margin-left:5px;padding: 0;" type="text"> {{ item.voucherNo }}</qzf-button>
                      </el-tooltip>
                      <!-- <el-tooltip content="已冲红" placement="top"  effect="dark" v-if="item.voucherId">
                       <i style="cursor:pointer;color:red;font-size: 14px;margin-left: 1px;top: -2px;position: relative;" class="iconfont icon-gantanhao1"></i>
                      </el-tooltip> -->
                    </li>
                    <li style="width:120px" class="ccyc">制单人：
                      <span style="color:#39b0d2" >{{ item.createdBy == 1 ? '系统自动' : item.createName}}</span>
                    </li>
                    <li style="width:110px" class="ccyc">审核人：
                      <span style="color:#39b0d2">{{ item.checkName}}</span>
                    </li>
                    <li style="width:110px" class="ccyc">附单据：
                      <span style="color:#39b0d2">{{ item.billCount}}张</span>
                    </li>
                    <li>来源：
                      <span style="color:#39b0d2">{{ $VoucherSourceFilter(item.fromType)}}</span>
                    </li>
                    
                  </ul>
                  <div class="top_right_btns">
                  
                    <!-- 可能用不要删 -->
                    <el-tooltip content="编辑" placement="top"  effect="dark">
                      <qzf-button button_code="pz_bj" @success="handleUpdate(item)" :disabled="item.period != accountBookPeriod" size="small" type="text" icon="Edit" style="margin:0"></qzf-button>
                    </el-tooltip>
                    <el-tooltip content="插入凭证" placement="top" effect="dark" >
                      <el-button  @click="insertVoucher(item)" size="small" type="text" icon="Connection" style="margin:0" :disabled="!$buttonStatus('pz_cr') || item.period != accountBookPeriod"></el-button>
                    </el-tooltip>
                    <!-- <el-tooltip content="复制" placement="top"  effect="dark">
                      <el-button  :disabled="!$buttonStatus('pz_fz')" @click="handleDup(item)" size="small" type="text" icon="DocumentCopy" style="margin:0"></el-button>
                    </el-tooltip> -->
                    <el-tooltip content="查看影像" placement="top"  effect="dark">
                      <el-button  @click="checkPdf(item)" size="small" type="text" icon="Printer" style="margin:0" :disabled="item.fromType != '1' && item.fromType != '2' && item.fromType != '6'"></el-button>
                    </el-tooltip>
                    <!-- <el-tooltip content="删除" placement="top"  effect="dark">
                      <qzf-button button_code="pz_plsc" size="small" @success="delVou(item)" type="text" icon="Delete" style="margin: 0;"></qzf-button>
                    </el-tooltip> -->
                  </div>
                </el-col>
              </el-row>

              <template v-for="(itemz,i) in item.voucherItem">
                <div v-if="i < voucherItemsLimit || item.showAll" class="bottom-nav" :key="itemz.id">
                  <el-row>
                    <el-col :span="9">
                      <div class="text_none">{{ itemz.summary }}</div>
                    </el-col>
                    <el-col :span="9">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top-start"
                        raw-content
                        >
                        <template #content>
                          <div style="max-width: 500px;">
                            {{itemz.fzhsName?itemz.subjectName+'--辅助核算：'+itemz.fzhsName:itemz.subjectName}}
                          </div> 
                        </template>
                        <div class="text_none"> {{itemz.fzhsName?itemz.subjectName+'--辅助核算：'+itemz.fzhsName:itemz.subjectName}}</div>
                      </el-tooltip>
                    </el-col>
                    <el-col :span="3">
                      <div>{{itemz.inAmount?this.$comdify(itemz.inAmount):""}}</div>
                    </el-col>
                    <el-col :span="3">
                      <div> {{itemz.outAmount?this.$comdify(itemz.outAmount):""}}</div> 
                    </el-col>
                  </el-row>
                </div>
              </template>
              
              <div class="bottom-nav">
                <el-button class="fold_icon not-table-show-btn" v-if="item.voucherItem?.length > voucherItemsLimit" @click="item.showAll = !item.showAll" size="small">
                  {{ !item.showAll ? '展开全部' : '收起全部' }}
                  <el-icon v-if="!item.showAll">
                    <ArrowDown />
                  </el-icon>
                  <el-icon v-else>
                    <ArrowUp />
                  </el-icon>
                </el-button>
                <el-row>
                <el-col :span="18">
                  <div style="" v-if="item.inSumAmount != 0">合计：{{ saveBig(item.inSumAmount) }}</div>
                </el-col>
                <el-col :span="3">
                <div>{{this.$comdify(item.inSumAmount)}}</div>
                </el-col>
                <el-col :span="3">
                <div> {{this.$comdify(item.outSumAmount)}}</div> 
                </el-col>
                </el-row>
              </div>
              
            </div>
          </div>
        </el-scrollbar>
      </div>
      </div>
    </div>
    <div>
      <div class="bottom_button">
        <qzf-button button_code="pz_yjsh" size="small" type="primary" @success="voucherSH" plain >
          <el-icon><Stamp /></el-icon><span  > 一键审核</span>
        </qzf-button>
        <qzf-button button_code="pz_yjfsh" size="small" type="primary" @success="voucherFSH" plain >
          <el-icon><Stamp /></el-icon><span  > 一键反审核</span>
        </qzf-button>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="voucher"/>
      </div>
    </div>
    <!-- 手动合并弹窗 -->
    <manualMerge ref="manualMerge" :allselect="this.allselect" @success="getList"></manualMerge>
    <!-- <printVouch ref="printVouch" :name="this.name1"></printVouch> -->
    <printVouchPz ref="printVouchPz" :listIds="allselect" name="book_voucher" :listQuery1="this.listQuery"></printVouchPz>
    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="凭证" @success2="getCallBackList"></qzf-import-back>
    <!-- 复制 -->
    <duplicate ref="duplicate" :dialogTitle="dialogTitle" :type="false" @success="getList"></duplicate>
    <!-- 导出 -->
    <exportFile ref="exportFile" :listIds="allselect" name="book_voucher" :listQuery1="this.listQuery"></exportFile>

    <oneKeyVoucher ref="oneKeyVoucher" @success="getVoucherStatus" :overVoucher="overVoucher"></oneKeyVoucher>

    <dragSort ref="dragSort" @success = "getList()"></dragSort>

    <sortVoucher @successSort="getList()" ref="sortVoucher"></sortVoucher>


    <!-- 汇兑损益 -->
    <dialogDate ref="dialogDate" @handleSuccess='getList()'></dialogDate>

    <!-- 汇率调整 -->
    <rateAdjust ref="rateAdjust" @success="getList()"></rateAdjust>

  </div>
  <!-- 图片预览 -->
  <el-dialog
    title="影像预览"
    v-model="showImagePreview"
    width="60%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-carousel :class="changeIndex==0?'leftBtnNone':changeIndex==imgSrc.length-1? 'rightBtnNone':''"   :autoplay="false" trigger="click" height="700px" @change="carousel" >
      <el-carousel-item v-for="item in imgSrc" :key="item">
        <el-scrollbar :maxHeight="700">
          <el-image :src="item" style="width: 100%"></el-image>
        </el-scrollbar>
      </el-carousel-item>
    </el-carousel>
    <div style="text-align: center">
      {{ changeIndex + 1 }} / {{ imgSrc.length }} 张
    </div>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogTableVisible"
    title="提示"
    width="500px"
  >
    <el-table :data="callList" border height="400px">
      <template #empty>
        <el-empty :image-size="160" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="name" label="凭证号" width="90" align="center">
        <template #default="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="失败原因" min-width="180" align="center">
        <template #default="scope">
          {{ scope.row.err }}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { salaryCarryOver,carryOverAsset } from '@/api/carryover.js'
import { voucherList, voucherDel , voucherSort ,editVoucherNo ,voucherLook,getVoucherPng } from "@/api/voucher.js";
import { wbVoucherFxOther } from '@/api/export'
import manualMerge from "./components/manualMerge.vue"
import printVouchPz from "./components/printVouchPz.vue"
import duplicate from "./components/duplicate.vue"
import exportFile from "./components/exportFile.vue"
import oneKeyVoucher  from './components/oneKeyVoucher.vue';
import dragSort  from './components/dragSort.vue';
import sortVoucher from "./components/sortVoucher";

import dialogDate from "./components/dialogDate";
import rateAdjust from '@/components/rateAdjust/index.vue'
import slidePeriod from "@/components/slidePeriod/slidePeriod.vue";
import { VOUCHER_SOURCE_OPTION } from '@/utils/commonData'
export default {
  name: "voucher",
  components:{
    manualMerge,
    printVouchPz,
    duplicate,
    exportFile,
    oneKeyVoucher,
    dragSort,
    sortVoucher,
    dialogDate,
    rateAdjust,
    slidePeriod
  },
  data() {
    return {
      dialogTitle:'',
      voucherItemsLimit: 20,
      orgId: this.$store.getters["user/user"].orgId,
      loading: false,
      loading1: false,
      loading2: false,
      listQuery:{
        comId: this.$store.getters['user/comInfo'].comId * 1,
        orderBy: "period, replace(voucher_no,'记','')+0",
        limit:50,
        page:1,
        checkStatus:0,
        itemName:'',
        startMoney:"",
        endMoney:"",
        startVoucherNo:null,
        endVoucherNo:null,
        startDate:'',
        endDate:'',
      },
      list: [],
      total: 0,
      select: [],
      allselect:[],
      allselect1: [],
      allselect2: [],
      contentStyleObj1:{},
      fromOptions:VOUCHER_SOURCE_OPTION,
      formLabelWidth: "100px",
      overVoucher:false,
      modeType:true,
      isShowTooltip:true,
      setScrollTop:'',
      setScrollTopFalse:"",
      totalPage:0,
      pageSize:40,
      tableData:[],
      currentPage:1,
      allChecked:false,
      showImagePreview:false,
      imgSrc:[],
      showStatus:true,
      changeIndex:0,
      dialogTableVisible:false,
      callList:[],
      accountBookPeriod:this.$store.getters["user/comInfo"].period
    }
  },
  deactivated(){
    if(!this.$getCachedViews("voucher")){//销毁 mitt bus
      this.$bus.off("voucherUpdate")
    }
  },
  activated(){
    this.setScrollTop = localStorage.getItem('scrollTop') ? localStorage.getItem('scrollTop') : 0
    this.setScrollTopFalse = localStorage.getItem('scrollTopFalse') ? localStorage.getItem('scrollTopFalse') : 0
    if(this.list.length != 0 && this.modeType){
      this.$refs.tableScroll.setScrollTop(this.setScrollTop * 1)
    }else if(this.list.length != 0 && !this.modeType){
      this.$refs.scrollbar.setScrollTop(this.setScrollTopFalse*1)
    }
  },
  created() {
    this.listQuery.limit = localStorage.getItem('voucher') ? localStorage.getItem('voucher')*1 : 50
    this.contentStyleObj = this.$getHeight(220)
    this.contentStyleObj1 = this.$getHeight(266)
    this.getList()
    this.initBus();
    if(localStorage.getItem("modeType") != undefined){
      this.modeType = localStorage.getItem("modeType") == 'true'
    }
    if(localStorage.getItem("voucherLeftShow") != undefined){
      this.showStatus = localStorage.getItem("voucherLeftShow") == 'true'
    }
  },
  mounted(){
    if(this.modeType){
      this.$refs.tableScroll.$refs.bodyWrapper.addEventListener('scroll',(res) => {
        this.setScrollTop = res.target.scrollTop
        localStorage.setItem('scrollTop',res.target.scrollTop)
      },true)
    }
  },
  computed: {
    // 是否全选
    isAllSelected() {
      //console.log(1);
      if(this.list.length == 0){
        return false
      }
      return this.tableData.every(el => {
        return el.checked;
      });
    },
  },
  methods:{
    carousel(val){
      this.changeIndex=val
      if(this.imgSrc.length-1 == val){
        this.$qzfMessage("已经是最后一张了", 1) 
      }
      if(val==0){
        this.$qzfMessage("当前是第一张", 1) 
      }
    },
     //单选
     singleSelect(row,index) {
      row.checked = !row.checked;
      this.list.splice(index, 1, row);
      this.screenChecked()
    },
    // 全选、全不选
    allSelect() {
      this.allChecked = !this.allChecked
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.tableData = this.tableData.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    screenChecked(){
      this.select = this.tableData.filter(v=>{return v.checked})//所有
      this.getIds()
    },
    getIds(){
        let arr = [];
        this.select.map(v => {
          arr.push(v.id);
        });
        this.allselect = arr;
        let arr1 = [];
        this.select.map(v => {
          arr1.push({id:v.id,checkStatus:1});
        });
        this.allselect1 = arr1;
        let arr2 = [];
        this.select.map(v => {
          arr2.push({id:v.id,checkStatus:0});
        });
        this.allselect2 = arr2;
    },
    handleSelectionChange(val){
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.id == v.id){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.select = newArr
      }else{
        this.select = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.select = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.select = []
        this.allselect = [];
        this.allselect1 = [];
        this.allselect2 = [];
        this.allChecked = false
      }
    },
    drag(){
      if(this.$checkSettleStatus()) return
      this.$refs.sortVoucher.getList()
      this.$refs.sortVoucher.dialogSend = true
    },
    daochuVoucher(){
      this.$refs.exportFile.init()
    },
    changeSort(e){
      if(e.order == 'descending'){
        this.descending = 'desc'
        this.listQuery.orderBy = "period,voucher_date,replace(voucher_no,'记','')+0 desc"
      }else{
        this.descending = ''
        this.listQuery.orderBy = "period,voucher_date,replace(voucher_no,'记','')+0"
      }
      this.getList();
    },
    dayin() {
      if(this.allselect.length == 0){
        this.$message.error('请至少选择一张要打印的凭证');
        return
      }
      this.$refs.printVouchPz.init()
    },
    initBus(){
      this.$bus.on("voucherUpdate", (val) => {
        this.getList('bus')
      });
    },
    //复制
    handleDup(row) {
      this.$refs.duplicate.init([row.id])
    },
    batchCopy(val){
      if(this.allselect.length == 0){
        this.$message.error('请至少选择一张要复制的凭证');
        return
      }
      if(val == 'fz'){
        this.dialogTitle = '复制凭证'
        this.$refs.duplicate.init(this.allselect, 1)
      }else{
        this.dialogTitle = '冲红凭证'
        this.$refs.duplicate.init(this.allselect, 2)
      }
    },
  
    getList(flag){
      this.loading1 = true
      voucherList(this.listQuery).then(res=>{
      this.loading1 = false
        if(res.data.msg == 'success'){
          this.tableData = (res.data.data.list || []).map(item => {
            return {
              ...item,
              checked: false,
              showAll: false,
            }
          });
        }
        this.total = res.data.data.total
        this.setScroll(flag)
      })
    },
    setScroll(flag){
      //先重置状态和数组
      this.allChecked = false
      this.select = [];
      this.allselect = [];
      this.allselect1 = [];
      this.allselect2 = [];
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      if(this.modeType){
        this.lazyLoading()
      }else{
        this.lazyLoadingModeFalse()
      }
      if(flag == 'bus'){
        this.setScrollTop = localStorage.getItem('scrollTop') ? localStorage.getItem('scrollTop') : 0
        this.setScrollTopFalse = localStorage.getItem('scrollTopFalse') ? localStorage.getItem('scrollTopFalse') : 0
        if(this.list.length != 0 && this.modeType){
          this.$refs.tableScroll.setScrollTop(this.setScrollTop * 1)
        }else if(this.list.length != 0 && !this.modeType){
          this.$refs.scrollbar.setScrollTop(this.setScrollTopFalse*1)
        }
      }
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.select.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.select.length != 0){
              that.select.map(v=>{
                that.list.map(item=>{
                  if(item.id == v.id){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    lazyLoadingModeFalse(){
      let lazy = document.getElementById("boxScrollbar");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.select.length == that.tableData.length){
              that.list.map(v=>{
                v.checked = true
              })
              that.allChecked = true
            }
          }
        }
      });
    },
    //一键审核
    voucherSH() {
      if(this.allselect1.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.loading1 = true
      voucherLook(this.allselect1).then(res => {
        this.loading1 = false
        if(res.data.msg) {
          this.$qzfMessage("一键审核成功")
          this.getList()
        }
      })
    },
    //一键反审核
    voucherFSH() {
      if(this.allselect2.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.loading1 = true
      voucherLook(this.allselect2).then(res => {
        this.loading1 = false
        if(res.data.msg) {
          this.$qzfMessage("一键反审核成功")
          this.getList()
        }
      })
    },
    addVoucher() {
      if(this.$checkSettleStatus()) return
      this.$store.dispatch('commons/getSubjects', this.listQuery.comId)
      this.$store.dispatch('commons/setParam', {addVoucherId: 0})
      this.$store.dispatch('commons/setParam', {insertVoucherNo: ""})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher",
        query:{type:'add'}
      });
    },
    handleUpdate(e){
      // if(e.checkStatus == 1){
      //   this.$qzfMessage("此条已审核,请先进行反审核", 1)
      //   return
      // }
      this.$store.dispatch('commons/getSubjects', this.listQuery.comId)
      this.$store.dispatch('commons/setParam', {addVoucherId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher",
        query:{type:'edit'}
      });
    },
    delAll(){
      if(this.select.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.voucherDel(this.select)
    },
    delVou(row){
      this.voucherDel([{id: row.id}])
    },
    voucherDel(ids){
      this.$confirm(`确认删除${ids.length}张凭证吗？`, "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'
      }).then(()=>{
        this.loading1 = true
        voucherDel(ids).then(res=>{
          this.loading1 = false
          if(res.data.msg == "success"){
            this.$qzfMessage(`删除成功，已删除${ids.length}张凭证`)
            this.$bus.emit('invioceInUpdate')
            this.$bus.emit('invioceOutUpdate')
            this.$bus.emit('invioceFeeUpdate')
            this.$bus.emit('bankUpdate')
            this.$bus.emit('cashUpdate')
            this.$bus.emit('billUpdate')
            this.$bus.emit('inventoryEstimated')
            this.getList()
          }
        })
      })
    },
    handleCommand(command) {
      if(command == 'a'){
        if(this.$checkSettleStatus()) return
        this.$refs.habitualSetting.getList()
      }else if(command == 'b'){
        if(this.$checkSettleStatus()) return
        voucherSort({}).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("操作成功")
            this.getList()
            this.$bus.emit('invioceInUpdate')
          }
        })
      }else if(command == 'c'){
        if(this.$checkSettleStatus()) return
        voucherSort({type:'data'}).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("操作成功")
            this.getList()
            this.$bus.emit('invioceInUpdate')
          }
        })
      }else if(command == 'd'){
        if(this.$checkSettleStatus()) return
        if(this.allselect.length == 0){
          this.$message.error('请选择要合并的凭证')
          return
        }
        this.$refs.manualMerge.dialogUp()
      }
    },
    //一键结转
    async carryForward() {
      if(this.$checkSettleStatus()) return
      this.loading2 = true
      await salaryCarryOver({}).then(res => {
        this.loading2 = false
        if(res.data.msg != 'success'){
          return
        }
      });
      await carryOverAsset({}).then(res => {
        this.loading1 = false
        if(res.data.msg != 'success'){
          return
        }
      })
      this.getList()
      this.$message({
        message:'结转成功',
        type:'success'
      })
    },
    //结转
    handleCommand1(command) {
      if(this.$checkSettleStatus()) return
      if(command == 'b'){
        this.loading1 = true
        salaryCarryOver({}).then(res => {
          this.loading1 = false
          if(res.data.msg=='success'){
            this.$message({
              message:'结转成功',
              type:'success'
            })
          }else{
            this.$message.error(res.data.msg);
          }
          this.getList()
        })
      }else if(command == 'd'){
        this.loading1 = true
        carryOverAsset({}).then(res => {
          this.loading1 = false
          if(res.data.msg=='success'){
            this.$message({
              message:'结转成功',
              type:'success'
            })
          }else{
            this.$message.error(res.data.msg);
          }
          this.getList()
        })
      }
    },
    // 汇兑损益
    huidui(){
      if(this.$checkSettleStatus()) return
      this.$refs.dialogDate.dialogBox = true;
    },

    paixu(row){
      if(this.$checkSettleStatus()) return
      this.$prompt('请输入凭证号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '格式不正确'
      }).then(({ value }) => {
        editVoucherNo({id:row.id,voucherNo:value}).then(res=>{
          if(res.data.msg == "success"){
            this.getList();
            this.$qzfMessage("修改成功")
            this.$bus.emit('invioceInUpdate')
            this.$bus.emit('invioceOutUpdate')
            this.$bus.emit('invioceFeeUpdate')
            this.$bus.emit('bankUpdate')
            this.$bus.emit('cashUpdate')
            this.$bus.emit('billUpdate')
          }
        })
      })
    },
    becomeVoucher(){
      this.$refs.oneKeyVoucher.openDialog()
      return
    },
    openCom(){
      if(this.$checkSettleStatus()) return
      this.$refs.importBck.handImport()
    },
    getVoucherStatus(val){
      if(!val){
        this.overVoucher = true
        setTimeout(() => {
          this.overVoucher = false
        }, 30000);
      }
    },
    onMouseOver(str){
      let contentWidth = this.$refs[str].offsetWidth;
        // 判断是否开启tooltip功能
      if (contentWidth > 243) {
        this.isShowTooltip = false;
      } else {
        this.isShowTooltip = true;
      }
      
    },
    changeMode(){
      this.modeType = !this.modeType;
      localStorage.setItem("modeType",this.modeType);
      this.getList()
    },
    saveBig(money) {
      let statusFu = false
      if(money < 0){
        money = -money
        statusFu = true
      }
      //汉字的数字
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      );
      //基本单位
      var cnIntRadice = new Array("", "拾", "佰", "仟");
      //对应整数部分扩展单位
      var cnIntUnits = new Array("", "万", "亿", "兆");
      //对应小数部分单位
      var cnDecUnits = new Array("角", "分", "毫", "厘");
      //整数金额时后面跟的字符
      var cnInteger = "整";
      //整型完以后的单位
      var cnIntLast = "圆";
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = "";
      //分离金额后用的数组，预定义
      var parts;
      if (money == "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "";
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != "") {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == "") {
        chineseStr += cnInteger;
      }

      if(statusFu){
        chineseStr = "负" + chineseStr
      }

      return chineseStr;
    },
    //汇率调整
    rate(){
      this.$nextTick(()=>{
        this.$refs.rateAdjust.init()
      })
    },
    // 回收站
    recycle(){
      this.$router.push({
        path: "/bookkeeps/recycle",
        name: "recycle"
      });
    },
    //凭证影像
    checkPdf(row){
      if(row.fromType != '6'){
        getVoucherPng({voucherId:row.id}).then(res=>{
          if(res.data.data.image){
            this.showImagePreview = true
            console.log(this.showImagePreview);
            let arr = []
            res.data.data.image.map(v=>{
              if(v.indexOf("https") > -1){
                arr.push(v)
              } else {
                arr.push('https://file.listensoft.net' + v)
              }
            })
            this.imgSrc = arr
          }
        })
      }else{
        let param = {
          outType:"pdf",
          exportType:"voucher_image_view",
          query:{
            voucherId:row.id
          }
        }
        wbVoucherFxOther(param).then(res=>{
          console.log(res);
          if(res.data.msg == 'success'){
            window.open(res.data.data.url)
          }
        })
      }
    },
    //插入凭证
    insertVoucher(row){
      if(this.$checkSettleStatus()) return
      this.$store.dispatch('commons/getSubjects', this.listQuery.comId)
      this.$store.dispatch('commons/setParam', {addVoucherId: 0})
      this.$store.dispatch('commons/setParam', {insertVoucherNo: row.voucherNo})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    scrollEvent(e){
      // 滚动条到顶部的位置
      const scrollTop = e.scrollTop
      localStorage.setItem('scrollTopFalse',scrollTop)
    },
     // 组件
     getPeriod(item) {
      this.listQuery.period = item.period;
      this.getList()
    },
     //重置
     cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        comId: this.$store.getters['user/comInfo'].comId * 1,
        orderBy: "period, replace(voucher_no,'记','')+0",
        limit:originLimit,
        page:1,
        checkStatus:0,
        itemName:'',
        startMoney:"",
        endMoney:"",
        startVoucherNo:null,
        endVoucherNo:null,
        startDate:'',
        endDate:'',
      }
      this.getList()
    },
    addVoucherDemo(){
      this.$store.dispatch('commons/getSubjects', this.listQuery.comId)
      this.$store.dispatch('commons/setParam', {addAllVoucherId: 0})
      this.$store.dispatch('tagsView/delCachedViewByName', "addAllVoucher")
      this.$router.push({
        path: "/bookkeeps/addAllVoucher",
        name: "addAllVoucher"
      });
    },
    //账期选择缓存
    changeShowStatus(e){
      this.showStatus = e;
      localStorage.setItem("voucherLeftShow",this.showStatus);
    },
    //导入回调
    getCallBackList(e){
      this.callList = e
      this.dialogTableVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.leftBtnNone {
  :deep(.el-carousel__arrow--left) {
        display: none !important;
    }
}
.rightBtnNone {
  :deep(.el-carousel__arrow--right) {
        display: none !important;
    }
}
.el-table :deep(.cell){
  color: #212529;
  overflow: unset
}
.none-border p {
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #b9b9b9;
    text-indent: 1em;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .none-border p:first-child {
    border-top: none;
  }
.top_btns {
  margin-bottom: 38px;
   .left_btns{
    float: left;
    display: flex;
    align-items: center;
  }
  .right_btn {
    float: right;
    text-align: right;
  }
}
.styleForm{
  margin:21px 0px 0px 16px;width:430px;
  .el-select{
    margin-left: 0px;
  }
}
.bottom_button{
  display: inline-block;
  margin-top: 20px;
}
</style>
<style lang="scss" scoped>
.left_box {
  float: left;
  width: 2%;
  input {
    width: 80%;
    height: 16px;
  }
}
.right_btns{
  width: 3%;
  float: right;
  text-align: right;
  margin-bottom: 10px;
}
.top_title {
  display: flex;
  width: 100%;
}
.right_box {
  //width: 99%;
  width: 100%;
}
.top-nav {
  height: 36px;
  background: var(--themeColor,#17a2b8);
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
  border-bottom: 1px solid #b9b9b9;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    line-height: 34px;
    font-size: 13px;
    height: 34px;
  }
}
.bottom-nav{
  height: 36px;
  font-size: 13px;
  position: relative;
  color: #212529;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #b9b9b9;
    line-height: 34px;
    font-size: 13px;
    height: 34px;
  }
}
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.content_center {
  display: flex;
  position: relative;
}
.content-nav {
  line-height: 36px;
  border-bottom: none;
  color: #212529;
  
  .content-top {
    background: var(--themeColorLeftMenu,#ecf7f9);
    .box-content {
      box-sizing: border-box;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
    }
    .box-content:first-child(1) {
      border-left: none;
    }
  }
  .for-content {
    .box-content {
      box-sizing: border-box;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      padding: 0 10px;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
  }
  .bottom-content {
    .box-content {
      box-sizing: border-box;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
  }
}
.top_font {
  float: left;
  width: 83%;
  margin: 0 auto;
  li {
    font-size: 13px;
    color: #212529;
    float: left;
   // width: 18%;
    text-align: left;
    padding-left: 15px;
    margin-right: 10px;
  }
}
.top_right_btns {
  float: right;
  margin-right:10px;
}
.top_right_btn2 {
  float: right;
  margin-right: 40px;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 17px;
    line-height: 24px;
  }
}

.el-col-9 {
  border-right: 1px solid #b9b9b9;
  div{
    padding-left: 10px;
    box-sizing: border-box;
  }
  
}
.el-col-3 {
  border-right: 1px solid #b9b9b9;
  text-align: right;
  padding-right: 10px;
}
.el-col-18 {
  border-right: 1px solid #b9b9b9;
  padding-left: 10px;
}

.content_center:last-child{
    .content-nav{
      border-bottom: 1px solid #b9b9b9;
    }
}
.el-row{
  display: flex;
  border: 1px solid #b9b9b9;
  border-bottom: none;
}
.bottom-nav{
  .el-col-3:last-child{
    border-right: none;
  }
}
.top_right_btns :deep(.el-icon){
  color: var(--themeColor,#17a2b8);
  font-size: 17px;
}
.text_none{
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.pic{
  position: absolute;
  right: 100px;
  z-index: 3;
  top: 25px;
  opacity: 0.3;
}
.iconfont{
  font-size: 17px;
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.fold_icon {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  padding: 0 10px;
  border-radius: 14px;
  font-size: 11px !important;
  height: 20px;
  line-height: 20px;
  &.not-table-show-btn {
    bottom: unset;
    right: unset;
    top: -10px;
    left: 57%;
    transform: translateX(-50%);
  }
}
:deep(.el-table .el-table__cell) {
  z-index: unset;
}
</style>
<style>
.none-border .cell {
  padding: 0;
}
.none-border .el-table__row td {
  padding: 0 !important;
}

.none-border .cell {
  padding: 0;
  text-align: center;
}
.width120 {
  width: 120px;
}
.mr-10 {
  margin-right: 10px;
}
.w-110 {
  width: 110px;
  .el-input-number__decrease, .el-input-number__increase{
    display: none!important;
  }
  .el-input-number .el-input__inner{
    text-align: left!important;
  }
}
.w-234 {
  width: 234px;
}
.mr-4 {
  margin-right: 4px;
}
.change-style {
  cursor: pointer;
  margin-left: 10px;
  font-size: 13px;
}
</style>
<template>
  <div>
    <el-dialog :close-on-click-modal="false" v-model="dialogFormVisible" title="汇率调整" width="90%">
      <div>
        <div style="text-align: center;font-size: 16px;">
          期末汇率调整表
        </div>
        <div style="text-align: center;padding: 10px 0;">
          <div>期间：{{ period }}</div>
        </div>
        <div class="top_select">
          <subject-list v-model:subjectId="subjectId" :codes="$findCode(code)" :disabledIn="disabled"></subject-list>

          <el-button type="primary" size="small" style="float: right;" @click="printWbVoucher">打印</el-button>
          <el-button type="success" size="small" style="float: right;margin-right: 10px;" @click="exportWbVoucher">导出</el-button>
        </div>
        <div>
          <el-table :data="list" border show-summary :height="contentStyleObj">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column label="科目编码" min-width="150" show-overflow-tooltip>
              <template #default="scope">
                <div>{{ scope.row.subjectCode }}</div>
              </template>
            </el-table-column>
            <el-table-column label="科目名称" min-width="120" align="center" show-overflow-tooltip>
              <template #default="scope">
                <div>{{ scope.row.subjectName }}</div>
              </template>
            </el-table-column>
            <el-table-column label="币别" min-width="100" align="center">
              <template #default="scope">
                <div>{{ scope.row.wb }}</div>
              </template>
            </el-table-column>
            <el-table-column label="原币金额" min-width="100" align="center">
              <template #default="scope">
                <div>{{ scope.row.periodEndInWb }}</div>
              </template>
            </el-table-column>
            <el-table-column label="调整前本位币金额" min-width="110" align="center">
              <template #default="scope">
                <div>{{ scope.row.periodEndIn }}</div>
              </template>
            </el-table-column>
            <el-table-column label="汇率" min-width="120" align="center">
              <template #header>
                <span>汇率</span>
                  <i @click="selectAllRate('rate')" class="iconfont icon-piliangcaozuo" style="margin-left: 3px;cursor: pointer;"></i>
              </template>
              <template #default="{ row }">
                <el-input size="small" style="width: 120px" @change="changeList(row)" v-model="row.rate"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="调整后本位币金额" min-width="120" align="center">
              <template #default="{ row }">
                <el-input size="small" style="width: 120px" @change="changeList(row)" v-model="row.tzh"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="调整前后差额" min-width="120" align="center" prop="se">
              <template #default="{ row }">
                <el-input size="small" style="width: 120px" @change="changeList(row)" v-model="row.amount"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" @click="save()" plain :disabled="list.length == 0">
            保存
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { wbVoucherFxList, wbVoucherFx } from "@/api/carryover";
import { wbVoucherFxOther} from "@/api/export";

export default {
  data() {
    return {
      dialogFormVisible: false,
      contentStyleObj: {},
      list: [],
      period: this.$store.getters["user/comInfo"].period,
      code: ["code5603"],
      subjectId: 0,
      disabled: false
    };
  },
  mounted() { },
  created() {
    this.contentStyleObj = this.$getHeight(350);
  },
  methods: {
    init() {
      this.dialogFormVisible = true;
      this.disabled = false;
      wbVoucherFxList({}).then((res) => {
        if (
          res.data.msg == "success" &&
          res.data.data.list != null &&
          res.data.data.list
        ) {
          this.list = res.data.data.list;
          this.subjectId = res.data.data.subjectId;
          if (res.data.data.subjectId) {
            this.disabled = true
          }
        } else {
          this.list = [];
        }
      });
    },
    changeList(row) {
      if(isNaN(Number(row.rate))){
        this.$qzfMessage('请输入正确的汇率',1)
        return
      }
      if (row.rate && !isNaN(Number(row.rate))) {
        row.rate = Number(row.rate)
        row.tzh = Number((Number(row.periodEndInWb) * Number(row.rate)).toFixed(4));
        row.amount = Number((Number(row.periodEndIn) - Number(row.tzh)).toFixed(4))
      }
    },
    save() {
      let status = false;
      this.list.map((v) => {
        if (v.rate == 0) {
          status = true;
          return;
        }
        v.rate = v.rate.toFixed(4) * 1;
      });
      if (status) {
        this.$qzfMessage("请填写汇率", 1);
        return;
      }
      if (!this.subjectId) {
        this.$qzfMessage("请选择科目", 1);
        return;
      }
      this.list.map(v => {
        v.tzh = v.tzh.toFixed(2)*1
        v.amount = v.amount.toFixed(2)*1
      })
      let param = {
        item: this.list,
        subjectId: this.subjectId,
      };
      wbVoucherFx(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.dialogFormVisible = false;
          this.$emit("success");
        }
      });
    },
    printWbVoucher(){
      let param = {
        exportType:"wb_voucher_fx",
        outType:'pdf'
      }
      wbVoucherFxOther(param).then(res => {
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    exportWbVoucher(){
      let param = {
        exportType:"wb_voucher_fx",
        outType:'excel'
      }
      wbVoucherFxOther(param).then(res => {
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    selectAllRate(e){
      this.list.map(v=>{
        v[e] = this.list[0][e]
        this.changeList(v)
      })
    }
  },
};
</script>

<style scoped lang="scss">
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.top_select {
  // display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
</style>
